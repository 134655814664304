import React, { useCallback, useEffect } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import {
  ContentfulComposePageType,
  ContentfulGeneralPageType,
} from "../types/contentful.types"
import BackgroundContainer from "../components/background-container"
import Footer from "../components/footer"
import DownloadPopup from "../components/download-popup"
import { useState } from "react"
import Sections from "../components/sections/sections"
import Menu from "../components/menu/menu"
import Hero from "../components/hero"
import MenuMobile from "../components/menu/menu-mobile"
import { isMobile } from "react-device-detect"
import useQueryParams from "query-params-hook"
import CarnivalCruisePopUp from "../components/CarnivalCruisePopUp"

declare const window: any

export const query = graphql`
  query GeneralPageTemplateQuery($slug: String!) {
    contentfulGeneralPage(
      compose__page: { elemMatch: { slug: { eq: $slug } } }
    ) {
      hero {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          title
          file {
            contentType
            url
          }
        }
        imageMobile {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          title
          file {
            contentType
            url
          }
        }
        videoPlaceholder {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          title
          file {
            contentType
            url
          }
        }
        imageNearTheText {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 40)
            }
            publicURL
          }
        }
        imageNearTheTextPosition
        text {
          raw
          references {
            ... on ContentfulAsset {
              __typename
              id
              contentful_id
              description
              mimeType
              file {
                url
                fileName
                contentType
              }
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        textMobile {
          raw
        }
        buttonAdjustLink {
          android
          ios
        }
      }
      sections {
        columns {
          __typename
          ... on ContentfulComponentSocialProof {
            name
            title
            items {
              author
              date(formatString: "MMM DD, YYYY")
              socialImage {
                gatsbyImageData(width: 200, height: 48)
              }
              authorImage {
                gatsbyImageData
              }
              text {
                raw
              }
            }
          }
          ... on ContentfulComponentYoutubeVideo {
            id
            youtubeUrl {
              youtubeUrl
            }
          }
          ... on ContentfulComponentSlotsWithFilter {
            id
            internalName
            slots {
              slotName
              slotIcon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 300, quality: 20, layout: CONSTRAINED, placeholder: BLURRED)
                  }
                }
              }
              gameIframeUrl
              longGameIframeUrl {
                longGameIframeUrl
              }
              slotInfoLink
              compose__page {
                title
                slug
                createdAt
              }
              categories {
                id
                title
              }
            }
          }
          ... on ContentfulComponentSlotsTypes {
            id
            __typename
            slots {
              id
              compose__page {
                slug
                title
              }
              categories {
                title
                id
              }
              gameIframeUrl
              longGameIframeUrl {
                longGameIframeUrl
              }
              slotInfoLink
              slotIcon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 20, layout: CONSTRAINED, placeholder: BLURRED)
                  }
                }
                title
              }
              description {
                raw
              }
            }
          }
          ... on ContentfulComponentGallery {
            id
            __typename
            galleryType
            title
            media {
              title
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1920, quality: 20)
                }
              }
            }
          }
          ... on ContentfulComponentTextImage {
            id
            __typename
            text {
              raw
              references {
                ... on ContentfulComponentImageLink {
                  __typename
                  contentful_id
                  title
                  linkToUrl
                  openInNewWindow
                  image {
                    localFile {
                      publicURL
                    }
                  }
                  linkToInnerPage {
                    slug
                  }
                }
                ... on ContentfulComponentStores {
                  contentful_id
                  __typename
                  storesList {
                    name
                    mobile {
                      mobile
                    }
                    desktop {
                      desktop
                    }
                  }
                }
              }
            }
            textBg
            imagesSide
            gallery {
              id
              media {
                id
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1920, quality: 30)
                  }
                }
                title
                file {
                  contentType
                  url
                }
              }
            }
          }
          ... on ContentfulComponentSlotsTypes {
            id
            __typename
            description {
              description
            }
            showDescription
          }
          ... on ContentfulComponentCards {
            id
            title
            additionalText {
              raw
            }
            bottomText {
              raw
            }
            iconPlace
            showTitles
            backgroundColor
            itemsInRow
            cards {
              id
              title
              iconSizeOnMobile
              readMore {
                compose__page {
                  slug
                }
              }
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 80)
                  }
                }
                title
              }
              textAlignment
              textColor
              text {
                raw
              }
            }
          }
          ... on ContentfulComponentTextImageGroup {
            id
            __typename
            title
            spaceBetween
            description {
              raw
            }
            bottomText {
              raw
            }
            textImages {
              text {
                raw
                references {
                  ... on ContentfulComponentImageLink {
                    id
                    __typename
                    contentful_id
                    title
                    linkToUrl
                    openInNewWindow
                    image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                    linkToInnerPage {
                      slug
                    }
                  }
                  ... on ContentfulComponentStores {
                    contentful_id
                    __typename
                    storesList {
                      name
                      mobile {
                        mobile
                      }
                      desktop {
                        desktop
                      }
                    }
                  }
                }
              }
              textBg
              imagesSide
              gallery {
                id
                media {
                  id
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 1920, quality: 40)
                    }
                  }
                  title
                  file {
                    contentType
                    url
                  }
                }
              }
            }
          }
          ... on ContentfulComponentSimpleText {
            __typename
            contentful_id
            id
            backgroundColor
            text {
              raw
              references {
                ... on ContentfulComponentYoutubeVideo {
                  id
                  contentful_id
                  __typename
                  youtubeUrl {
                    youtubeUrl
                  }
                }
                ... on ContentfulComponentImageLink {
                  id
                  __typename
                  contentful_id
                  title
                  linkToUrl
                  openInNewWindow
                  image {
                    localFile {
                      publicURL
                    }
                  }
                  linkToInnerPage {
                    slug
                  }
                }
              }
            }
            images {
							localFile {
              	childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }

    contentfulComposePage(slug: { eq: $slug }) {
      impressionLink {
        impressionLink
      }
      seo {
        description
        keywords
        no_index
        no_follow
        title
        canonical
        schemaJson {
          schemaJson
        }
        additionalSchemaJson {
          additionalSchemaJson
        }
        socialImage {
          url
        }
      }
    }
  }
`

const GeneralPageTemplate = ({ location, data }) => {
  const { hero, sections }: ContentfulGeneralPageType =
    data.contentfulGeneralPage
  const { seo, impressionLink }: ContentfulComposePageType = data.contentfulComposePage
  const [showDownloadPopup, setShowDownloadPopup] = useState<boolean>(false)
  const [shouldShowBanner, setShouldShowBanner] = useState(false);
  const { lp: lpPages, lp_v: lpVersion } = useQueryParams()

  const onDownloadClick = useCallback(() => {
    if(location.pathname === '/') {
      typeof window !== `undefined` && typeof window.gtag === "function"
      ? window.gtag("event", "desktop_hp_popup_open")
      : null
    }
    setShowDownloadPopup(true)
  }, [location?.pathname]);

  const [isLpPage, setIsLpPage] = useState(true);
  const [lpV2, setLpV2] = useState(false);
  const [heroBG, setHeroBG] = useState(null as any);
  const [loadingClass, setLoadingClass] = useState('hidden');

  useEffect(() => {
    setHeroBG(isMobile && hero.imageMobile?.file?.url
        ? hero.imageMobile
        : hero.image)
    setLoadingClass(location.pathname.includes("/slot-games") ? 'slot-games' : '');
  }, [isMobile, hero])

  useEffect(() => {
    setIsLpPage(lpPages === "true");
    setLpV2(lpVersion === 'v2');
  }, [lpPages, lpVersion]);

  return (
    <div className={`${isLpPage ? `lp-pages ${lpVersion}` : ''} ${loadingClass} ${location.pathname === '/' ? "home-page" : ""}`}>
      <SEO
        data={seo}
        isLpPage={isLpPage}
        shouldShowBanner={shouldShowBanner}
        setShouldShowBanner={setShouldShowBanner}
        impressionLink={impressionLink?.impressionLink}
      />
      {/* <CarnivalCruisePopUp onClick={() => setShowDownloadPopup(true)} /> */}

      <DownloadPopup
        isLpPage={isLpPage}
        onCloseClick={() => setShowDownloadPopup(false)}
        show={showDownloadPopup}
      />

      <div className={`app-container ${shouldShowBanner ? "show-banner" : ""}`}>
        <MenuMobile
          light_version={isLpPage}
          shouldShowBanner={shouldShowBanner}
          lpV2={lpV2}
        />
        <Menu onDownloadClick={onDownloadClick} />

        <BackgroundContainer
          videoPlaceholder={hero.videoPlaceholder}
          image={heroBG}
          fader={hero.imageNearTheText ? false : true}
          className={
            location.pathname.includes("/slot-games") || location.pathname.includes("/free-coins") ? "custom-height" : ""
          }
        >
          <Hero data={hero} onDownloadClick={onDownloadClick} />
        </BackgroundContainer>
        <main>
          <Sections
            seo={seo}
            sections={sections}
            onDownloadClick={() => setShowDownloadPopup(true)}
          />
        </main>

        <Footer isLpPage={isLpPage} />
      </div>
    </div>
  );
}

export default GeneralPageTemplate;
