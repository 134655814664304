import React, { useMemo } from "react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { useLocation } from "@reach/router"
import { ContentfulGeneralPageType } from "../types/contentful.types"
import ContentfulRichText from "../utilities/contentful-rich-text"
import { isAndroid, isMobile, isTablet } from "react-device-detect"
import AdjustLink from "./adjust-link"
import '../styles/hero.scss';

import '../styles/hero.scss';

// !IMPORTANT
// Background Image of the hero is coming from page template to cover all the header

const Hero = ({ data, onDownloadClick }) => {
  const {
    text,
    textMobile,
    imageNearTheText,
    buttonAdjustLink
  }: ContentfulGeneralPageType["hero"] = data
  const { pathname } = useLocation()
  const excluded = ["/slot-games"];
  
  const ctaButton = useMemo(
    () => (
      <StaticImage
        className="mt-10 glow-animation"
        src="../images/get-the-app-button.png"
        alt="Download Now"
        width={300}
      />
    ),
    []
  );

  const HeroContent = () => {
    return (
      <>
        <ContentfulRichText className="rich-text text-white text-center px-16" raw={isMobile && !!textMobile ? textMobile : text} />
        {!excluded.find((item) => pathname.includes(item)) && ctaButton && (
          <div className="flex items-center justify-center">
            {isMobile || isTablet ? (
              <AdjustLink target={buttonAdjustLink ? "_blank" : ""} externalUrl={isAndroid ? buttonAdjustLink?.android : buttonAdjustLink?.ios}>{ctaButton}</AdjustLink>
            ) : (
              <button onClick={onDownloadClick}>{ctaButton}</button>
            )}
          </div>
        )}
      </>
    );
  }

  return (
    <div className={`hero${imageNearTheText ? " hero-image-near" : ""}`}>
      {imageNearTheText ? (
        <div className='hero-top'>
          <div className=''>
            <GatsbyImage
              image={
                getImage(
                  imageNearTheText.localFile.childImageSharp.gatsbyImageData
                )!
              }
              alt=''
            />
          </div>
          <div className='hero-content pt-8 mb-10 xl:mb-0 md:px-10'>
            <HeroContent />
          </div>
        </div>
      ) : (
        <div className='hero-content'>
          <HeroContent />
        </div>
      )}
    </div>
  )
}
export default Hero
